import React, { useContext } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import t from '../services/GetTranslationValue'
import ThemeContext from "../context/ThemeContext"
import styles from "./language.module.scss"
import { Button, Dialog } from "@material-ui/core"
import Helmet from "react-helmet"

const Index = () => {

  const {translations}= useContext(ThemeContext);

  return [<Helmet
    title="Mr. Gamble - Find the best online casinos, quickly and efficiently.">
    <link rel="alternate" hrefLang="en-gb"
    href="https://mr-gamble.com/uk/" />
    <link rel="alternate" hrefLang="en-ca"
    href="https://mr-gamble.com/ca/" />
    <link rel="alternate" hrefLang="en"
    href="https://mr-gamble.com/en/" />
    <link rel="alternate" hrefLang="fi"
    href="https://mr-gamble.com/fi/" />
    <link rel="alternate" hrefLang="x-default"
    href="https://mr-gamble.com/" />
  </Helmet>,
  <div className={`${styles.parent}`}>
      <div className={`${styles.container}`}>
        <img className={`${styles.gambleLogo}`} src={`/icons/tv_set@3x.png`} alt="Mr Gamble" />
        <p>Select locale - Valitse Sijainti</p>
        <a href={'/ca/'} aria-label={`Finnish ${t.get(translations, "language")}`}>
          <img src={`/icons/canada.svg`} alt={t.get(translations, 'ca-flag-alt')} />
          <span>Canadian casinos and bonuses</span>
        </a>  
        <a href={'/uk/'} aria-label={`Finnish ${t.get(translations, "language")}`}>
          <img src={`/icons/united-kingdom.png`} alt={t.get(translations, 'uk-flag-alt')} />
          <span>UK casinos and bonuses</span>
        </a>  
        <a href={'/fi/'} aria-label={`Finnish ${t.get(translations, "language")}`}>
          <img src={`/icons/finland.png`} alt={t.get(translations, 'fin-flag-alt')} />
          <span>Suomalaiset Kasinot ja Bonukset</span>
        </a>  
        <a href={'/en/'} aria-label={`Finnish ${t.get(translations, "language")}`}>
          <img src={`/icons/global.svg`} alt={t.get(translations, 'global-flag-alt')} />
          <span>Online Casinos and bonuses</span>
        </a>  
        <img className={`${styles.warningLogo}`} alt={`age restriction`} src={`/icons/Artboard-32.svg`} />
      </div>
    </div>]
}

export default Index
